@use '../../../styles/mixins' as mixins;

$prefix: '--select';
$menu-prefix: '--dropdown-menu';

.select {
  position: relative;
  width: calc(100% - 2rem);
  padding: 0.75rem 1rem;
  transition: background-color 0.3s;
  background-color: var(#{$prefix}-background-color);
  cursor: pointer;

  &--disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: var(#{$prefix}-background-hover-color);
  }

  &__header {
    @include mixins.flex-row(center, flex-start);

    &-content {
      @include mixins.flex-column(flex-start);

      width: calc(100% - 1rem);
      gap: 0.25rem;

      &-label {
        @include mixins.font-style(1rem, var(#{$prefix}-label-color), 600);

        line-height: 1.5rem;
      }

      &-title {
        @include mixins.font-style(0.875rem, var(#{$prefix}-title-color));

        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 1.25rem;

        &--without-label {
          @include mixins.font-style(1rem, var(#{$prefix}-title-color), 600);

          line-height: 1.5rem;
        }
      }
    }

    &-arrow {
      width: 1.25rem;
      height: 1.25rem;
      color: var(#{$prefix}-icon-color);

      &--up {
        @include mixins.transform(rotate(180deg));
      }
    }
  }

  &__list {
    @include mixins.custom-scrollbar();

    position: absolute;
    width: calc(100% - 1rem);
    left: 0.5rem;
    top: calc(100% + 0.5rem);
    list-style: none;
    max-height: 20rem;
    overflow-y: auto;
    border-radius: 0.25rem;
    background-color: var(#{$menu-prefix}-background-color);
    box-shadow: 0 0.125rem 0.375rem rgba(0, 0, 0, 0.045755),
      0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.101508);
    z-index: 3;

    &--static-position {
      position: static;
      width: 100%;
      margin: 0.5rem 0;
    }

    &-item {
      @include mixins.flex-row(center, space-between);
      @include mixins.font-style(1rem, var(#{$menu-prefix}-text-color));

      line-height: 1.5rem;
      padding: 0.75rem 1rem;

      &-title {
        @include mixins.ellipsis(calc(100% - 1rem));

        white-space: nowrap;
        cursor: pointer;

        &-hint {
          @include mixins.ellipsis();

          font-size: 0.75rem;
        }
      }

      &-check {
        color: var(#{$menu-prefix}-icon-color);
        width: 0.9375rem;
        height: 0.75rem;
      }

      &:hover {
        background-color: var(#{$menu-prefix}-item-background-hover-color);
      }
    }
  }

  &__search {
    position: relative;
    padding: 1rem;

    &-clear {
      position: absolute;
      right: 1.75rem;
      top: 1.75rem;
    }
  }
}
