@use '../../styles/mixins' as mixins;

$prefix: '--user-directory';

.user-directory {
  @include mixins.flex-column(initial);

  gap: 1rem;

  &__title {
    @include mixins.font-style(1.5rem, var(#{$prefix}-title-color), 600);

    line-height: 2rem;
  }

  &__feed {
    @include mixins.flex-column(initial);

    gap: 0.25rem;
    max-width: var(--content-max-width);
  }

  &__loader {
    @include mixins.flex-column(initial);

    gap: 0.5rem;
  }

  &__empty {
    @include mixins.flex-column();

    gap: 1rem;

    &-message {
      @include mixins.font-style(1rem, var(#{$prefix}-empty-message-color));

      line-height: 1.25rem;
    }
  }
}
