@use '../../../../../styles/mixins' as mixins;

$prefix: '--comments-input';

.comment-input {
  @include mixins.flex-column(flex-start);

  position: relative;
  gap: 0.5rem;

  &__file {
    display: none;
  }

  &__field {
    @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

    display: block;
    padding: 0.625rem 0 0 0;
    line-height: 1.375rem;
    background-color: var(#{$prefix}-background-color);
    border: 0;
    overflow: hidden;
    scroll-margin-bottom: 0.625rem;
    scroll-padding-bottom: 3.125rem;

    &::placeholder {
      color: var(#{$prefix}-placeholder-color);
    }
  }

  &__icons {
    @include mixins.flex-row(center, flex-start);

    width: 100%;
    gap: 0.75rem;

    &-send,
    &-loader {
      margin-left: auto;
    }
  }

  &__emoji-picker {
    position: absolute;
    top: calc(100% + 1rem);
    left: -1rem;
    z-index: 999;
  }
}
