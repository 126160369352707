@use '../../../styles/mixins' as mixins;

.chat-details {
  position: absolute;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #23232e80;
    opacity: 0;
    transition: opacity 0.3s;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  &__content {
    @include mixins.flex-column(flex-start, initial);

    position: absolute;
    right: -100%;
    width: 23.125rem;
    height: 100%;
    background-color: #fff;
    transition: right 0.3s;
    border-top-right-radius: 0.5rem;

    &-body {
      @include mixins.flex-column(center, initial);

      width: calc(100% - 2rem);
      padding: 1rem;
      gap: 1.5rem;

      &-group {
        @include mixins.flex-column(center, initial);

        width: 100%;
        gap: 0.5rem;

        &-name {
          @include mixins.font-style(1.25rem, #1e1e1e, 600);

          line-height: 1.75rem;
          word-break: break-word;
        }

        &-purpose {
          @include mixins.font-style(1rem, #787878);

          line-height: 1.25rem;
          word-break: break-word;
        }
      }

      &-info {
        @include mixins.flex-column(initial);

        width: 100%;
        gap: 1rem;

        &-title {
          @include mixins.font-style(1rem, #1e1e1e, 600);
        }

        &-item {
          @include mixins.flex-row(center, space-between);

          &-label {
            @include mixins.font-style(1rem, #787878);
          }

          &-value {
            @include mixins.font-style(1rem, #1e1e1e);
          }
        }
      }
    }
  }

  &--open {
    pointer-events: unset;
  }

  &--open .chat-details__content {
    right: 0;
  }

  &--open .chat-details__overlay {
    opacity: 1;
  }
}
