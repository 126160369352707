@use '../../../styles/mixins' as mixins;

.close {
  position: relative;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #b3b3b3;
  flex-shrink: 0;
  cursor: pointer;

  &__icon {
    @include mixins.position-centered();
  }
}
