@use '../../../../styles/mixins' as mixins;

$prefix: '--details-block-embed';

.embed-link {
  @include mixins.flex-row(flex-start, space-between);

  gap: 0.5rem;
  padding: 1rem;
  border: 0.0625rem solid var(#{$prefix}-border-color);
  transition: border-color 0.3s;

  &:hover {
    border-color: var(#{$prefix}-border-hover-color);
  }

  &__description {
    @include mixins.flex-column(flex-start);

    width: 100%;
    gap: 0.25rem;
    line-height: 1.5rem;

    &--with-teaser {
      width: calc(100% - 3.75rem - 0.25rem); //100% - teaser width - gap
    }

    &-source {
      @include mixins.font-style(1rem, var(#{$prefix}-source-color), 600);

      white-space: pre-wrap;
      word-break: break-word;
    }

    &-title {
      @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

      white-space: pre-wrap;
      word-break: break-word;
    }

    &-text {
      @include mixins.font-style(1rem, var(#{$prefix}-text-color));

      white-space: pre-wrap;
      word-break: break-word;
    }

    &-url {
      @include mixins.font-style(1rem, var(#{$prefix}-url-color), 600);
      @include mixins.flex-row(center, flex-start);

      width: 100%;
      gap: 0.5rem;
      line-height: 1.5rem;
    }

    &-link {
      max-width: calc(100% - 1.5rem);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__image {
    min-width: 3.75rem;
    height: 3.75rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &--instagram {
      background-image: url('../../../../assets/images/instagram.png');
    }

    &--facebook {
      background-image: url('../../../../assets/images/facebook.png');
    }

    &--twitter {
      background-image: url('../../../../assets/images/twitter.png'); //! deprecated
    }

    &--x {
      background-image: url('../../../../assets/images/x.png');
    }
  }
}
