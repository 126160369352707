@use '../../styles/mixins' as mixins;

$prefix: '--front-door-links';
$item-prefix: '--front-door-links-item';

.front-door-links {
  @include mixins.flex-column(initial, flex-start);

  gap: 1rem;

  &__title {
    @include mixins.font-style(2rem, var(#{$prefix}-title-color), 600);

    line-height: 2.75rem;
  }

  &__list {
    @include mixins.flex-column(initial, flex-start);

    gap: 1rem;

    &-item {
      @include mixins.flex-row(center, center);

      gap: 1rem;
      border: 0.0625rem solid var(#{$item-prefix}-border-color);
      box-sizing: border-box;
      padding: 0.5rem;
      cursor: pointer;

      &-image-wrapper {
        @include mixins.flex-row(center, center);

        width: 7.5rem;
        flex-shrink: 0;
        overflow: hidden;
      }

      &-image {
        max-width: 100%;
        object-fit: cover;
        transform-origin: 50% 50%;
        transition: transform 0.25s ease-in-out;
      }

      &-description {
        width: 100%;
      }

      &-title-wrapper {
        @include mixins.flex-row(flex-start, space-between);

        gap: 0.25rem;
      }

      &-title {
        @include mixins.font-style(1rem, var(#{$item-prefix}-title-color), 600);

        padding-right: 0.25rem;
        line-height: 1.5rem;
        word-break: break-word;
        white-space: pre-wrap;
        transition: 0.25s ease-in-out;
      }

      &-subTitle {
        @include mixins.font-style(1rem, var(#{$item-prefix}-subtitle-color));

        padding-right: 0.25rem;
        line-height: 1.5rem;
        word-break: break-word;
        white-space: pre-wrap;
        transition: 0.25s ease-in-out;
      }

      &-icon {
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        margin-left: auto;
      }

      &:hover {
        .front-door-links__list-item-title {
          color: var(#{$item-prefix}-title-hover-color);
        }

        .front-door-links__list-item-subTitle {
          color: var(#{$item-prefix}-subtitle-hover-color);
        }
      }
    }
  }
}
