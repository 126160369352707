@use '../../../styles/mixins' as mixins;

.abuse-report {
  @include mixins.flex-column(flex-start);

  gap: 0.5rem;

  &__title {
    @include mixins.font-style(1.75rem, #43445b, 600);
  }

  &__subTitle {
    @include mixins.font-style(0.875rem, #787878);

    margin-bottom: 1rem;
  }

  &__reasons {
    @include mixins.flex-column(flex-start);

    gap: 1rem;
    width: 100%;

    &-item {
      @include mixins.flex-row(center, space-between);

      width: 100%;
      gap: 1rem;
      cursor: pointer;
    }
  }

  &__submit {
    margin-left: auto;
  }
}
