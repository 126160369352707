@use '../../../../styles/mixins' as mixins;

$prefix: '--comments-input';

.comment-box {
  @include mixins.flex-row(flex-start);

  gap: 0.75rem;

  &__input-wrapper {
    width: calc(100% - 2rem);
    padding: 0 1rem 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: var(#{$prefix}-background-color);
    border: 0.0625rem solid transparent;

    &:focus-within {
      border-color: var(#{$prefix}-border-focus-color);
    }
  }
}
