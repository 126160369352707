@use '../../../styles/mixins' as mixins;

$prefix: '--external-tags-list';

.external-tags-list {
  @include mixins.flex-column(flex-start, flex-start);

  flex-wrap: wrap;
  background-color: var(#{$prefix}-background-color);
  width: calc(100% - 2rem);
  padding: 1rem;
  gap: 0.5rem;

  &__title {
    @include mixins.font-style(1rem, #43445b, 600);

    line-height: 1.5rem;
  }

  &__item {
    word-break: break-word;
    white-space: pre-wrap;
  }

  &__collapsible {
    @include mixins.font-style(0.875rem, #888b90);

    line-height: 1.25rem;
    cursor: pointer;
  }
}
