@use '../../styles/mixins' as mixins;

.chats {
  @include mixins.flex-row(flex-start, initial);

  position: relative;
  border: 0.0625rem solid #ebebeb;
  border-bottom-width: 0;
  border-radius: 0.5rem 0.5rem 0 0;
  height: calc(100vh - 6rem);
  margin-bottom: -1rem;
  box-sizing: border-box;
  background-color: #fff;

  &__header {
    @include mixins.flex-column(flex-start, initial);

    width: 23.125rem;
    height: calc(100vh - 6rem - 0.0625rem);
    border-right: 0.0625rem solid #ebebeb;
    flex-shrink: 0;

    &-search {
      @include mixins.flex-row(center, space-between);

      width: calc(100% - 2rem);
      height: 2.5rem;
      padding: 1rem;
      border-bottom: 0.0625rem solid #ebebeb;

      &-title {
        @include mixins.font-style(1.5rem, #43445b, 600);

        line-height: 1.75rem;
      }

      &-icons {
        @include mixins.flex-row();

        gap: 1rem;
      }
    }
  }
}
