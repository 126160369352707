@use '../../../styles/mixins' as mixins;

$link-prefix: '--base-link';
$details-prefix: '--details-block-common';

.rich-text-editor {
  width: 100%;

  :global(.codex-editor__loader) {
    display: none;
  }

  &--short-post-text-field {
    height: 7em;

    :global(.ce-toolbar__actions) {
      display: none;
    }

    :global(.codex-editor__redactor) {
      margin-right: 0;
    }

    :global(.ce-popover--inline) {
      display: none;
    }

    :global(.ce-popover--inline.ce-popover--opened) {
      display: block;
      transform: translateY(-4rem) translateX(-19%);
    }
  }

  a {
    text-decoration: none;
    color: var(#{$link-prefix}-text-color);

    &:hover {
      color: var(#{$link-prefix}-text-hover-color);
    }
  }

  mark {
    background: var(#{$details-prefix}-mark-background-color);
    color: inherit;
  }

  code {
    background: var(#{$details-prefix}-code-background-color);
    color: inherit;
  }
}

:global(.ct) {
  z-index: 99999;
}

:global(.ce-settings .ce-popover-item[data-item-name='convert-to']),
:global(.ce-popover-item-separator) {
  display: none;
}

:global(.cdx-checklist__item-text) {
  white-space: pre-wrap;
  word-break: break-word;
}
