@use '../../../styles/mixins' as mixins;

.autocomplete {
  @include mixins.flex-column(initial);

  gap: 0.25rem;
  width: 100%;

  &__value {
    @include mixins.flex-row(center, flex-start);

    gap: 0.5rem;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
  }

  &__list-item {
    @include mixins.flex-row(center, flex-start);

    position: relative;
    gap: 0.5rem;

    &-check {
      position: absolute;
      right: 1rem;
      color: #1e1e1e;
      width: 0.9375rem;
      height: 0.75rem;
    }
  }

  &__infinite-scroll {
    @include mixins.custom-scrollbar();
  }

  &__loading,
  &__empty {
    @include mixins.font-style(1rem, #787878);

    line-height: 1.25rem;
    padding: 1rem;
  }
}
