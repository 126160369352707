@use '../../../styles/mixins' as mixins;

.icon-label {
  @include mixins.flex-row(center, flex-start);

  gap: 0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;

  &--disabled,
  &--non-clickable {
    cursor: default;
    pointer-events: none;
  }

  &--with-padding {
    padding: 0.5rem 1rem;
  }

  &--data:hover {
    position: relative;

    &::after {
      content: attr(data);
      position: absolute;
      width: max-content;
      max-width: 20rem;
      left: 1.75rem;
      padding: 0.75rem;
      color: #43445b;
      background: #fff;
      border: 0.0625rem solid #ebebeb;
      border-radius: 0.25rem;
      word-break: break-word;
      white-space: pre-wrap;
      box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0.2);
      z-index: 3;
    }
  }

  &--data-hint-left:hover {
    &::after {
      left: unset;
      right: 1.75rem;
    }
  }

  &--data-hint-top:hover {
    &::after {
      @include mixins.transform(translate(-50%, 0));

      left: 50%;
      top: -3rem;
    }
  }

  &--data-hint-bottom:hover {
    &::after {
      @include mixins.transform(translate(-50%, 0));

      left: 50%;
      top: 2rem;
    }
  }

  &__icon {
    color: inherit;
    flex-shrink: 0;
  }

  &__label {
    color: inherit;

    &--small {
      @include mixins.font-style(0.75rem, inherit, 600);

      line-height: 1rem;
    }

    &--medium {
      @include mixins.font-style(0.875rem, inherit, 600);

      line-height: 1.15rem;
    }

    &--large {
      @include mixins.font-style(1rem, inherit, 600);

      line-height: 1.25rem;
    }
  }
}
