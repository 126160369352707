@use '../../styles/mixins' as mixins;

$prefix: '--story-feed';

.feed-items {
  @include mixins.flex-column(initial);

  gap: 1.5rem;

  &__header {
    @include mixins.flex-row(flex-start, flex-start);

    gap: 0.625rem;
    width: 100%;

    &--pointer {
      cursor: pointer;
    }
  }

  &__icon {
    position: relative;
    top: 0.25rem;
  }

  &__heading {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.5rem;
    max-width: calc(100% - 1.5rem - 0.625rem); //width - icon - gap

    &-title {
      @include mixins.font-style(2rem, var(#{$prefix}-title-color), 600);

      line-height: 2.3rem;
      white-space: pre-wrap;
      word-break: break-word;
      
      &--one-liner {
        @include mixins.ellipsis();
      }
    }

    &-subTitle {
      @include mixins.font-style(1rem, var(#{$prefix}-subtitle-color));

      line-height: 1.25rem;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  &__image-text-wrapper {
    @include mixins.flex-column(initial, flex-start);
  }

  &__image {
    max-width: 100%;
  }

  &__text {
    @include mixins.flex-column(flex-start, flex-start);

    gap: 0.5rem;
    background-color: var(#{$prefix}-overlay-color);
    padding: 1.5rem;
    width: calc(100% - 3rem);

    &-title {
      @include mixins.font-style(1.5rem, var(#{$prefix}-overlay-title-color), 700);

      line-height: 2rem;
      white-space: pre-wrap;
      word-break: break-word;
    }

    &-subTitle {
      @include mixins.font-style(1rem, var(#{$prefix}-overlay-subtitle-color));

      line-height: 1.5rem;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
