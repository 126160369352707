@use '../../../../styles/mixins' as mixins;

.chat {
  @include mixins.flex-row(flex-start, initial);

  width: calc(100% - 1.5rem);
  padding: 0.75rem;
  gap: 0.5rem;
  border-bottom: 0.0625rem solid #ebebeb;
  cursor: pointer;

  &:hover,
  &--active {
    background-color: #f8f8f8;
  }

  &:last-child {
    border-bottom: 0;
  }

  &__avatar {
    position: relative;

    // &-status {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0.1rem;
    //   width: 0.5rem;
    //   height: 0.5rem;
    //   border-radius: 50%;
    //   background: #0da068;
    //   border: 0.125rem solid #fff;
    // }
  }

  &__content {
    @include mixins.flex-column(flex-start, initial);

    width: calc(100% - 3.5rem);
    gap: 0.25rem;
    min-height: 3rem;

    &-header {
      @include mixins.flex-row(center, space-between);

      width: 100%;
      gap: 0.5rem;

      &-title {
        width: 100%;
        max-width: calc(100% - 3rem);

        &-text {
          @include mixins.font-style(1rem, #1e1e1e, 600);
          @include mixins.ellipsis();
        }
      }

      &-time {
        @include mixins.font-style(0.875rem, #787878);

        flex-shrink: 0;
      }
    }

    &-message {
      @include mixins.flex-row(flex-start, initial);

      width: 100%;
      gap: 0.5rem;

      &-text {
        @include mixins.font-style(0.875rem, #787878);

        word-break: break-all;
      }

      &-unread {
        @include mixins.font-style(0.875rem, #fff);

        width: fit-content;
        min-width: 1rem;
        min-height: 1rem;
        line-height: 1.5rem;
        background-color: #f37354;
        text-align: center;
        padding: 0 0.25rem;
        border-radius: 0.25rem;
        flex-shrink: 0;
        margin-left: auto;
      }
    }
  }
}
