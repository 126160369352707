@use '../../../../styles/mixins' as mixins;

.create-card {
  @include mixins.flex-row();

  position: relative;
  gap: 1rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  // background-color: #f8f8f8;
  background-color: #fff;
  border: 0.0625rem solid #ebebeb;

  &__input {
    // background-color: #f8f8f8;
    background-color: #fff;
  }

  &__button {
    flex-shrink: 0;
    width: 12rem;
  }
}
