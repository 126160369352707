@use '../../../styles/mixins' as mixins;

$prefix: '--signup';

.link {
  display: inline;
}

.text {
  @include mixins.font-style(0.875rem, var(#{$prefix}-text-color));

  width: 100%;
  text-align: center;
}
