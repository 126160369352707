@use '../../../../styles/mixins' as mixins;

$prefix: '--create-card';

.create-card {
  @include mixins.flex-column(flex-start, flex-start);

  gap: 1rem;
  width: 100%;

  &__title {
    @include mixins.font-style(1.25rem, var(#{$prefix}-title-color), 600);

    line-height: 1.75rem;
  }

  &__post-in {
    @include mixins.flex-row(center, flex-start);

    gap: 0.25rem;
    width: 100%;

    &-label {
      @include mixins.font-style(1rem, var(#{$prefix}-post-in-color), 600);
    }

    &-select {
      max-width: 12rem;

      &--disabled {
        padding: 0.75rem 0;
      }
    }

    &-buttons {
      @include mixins.flex-row(center, flex-start);

      gap: 1rem;
      margin-left: auto;
    }
  }

  &__content {
    border: 0.125rem solid #ebebeb;
    border-top: 0;
  }

  &__buttons {
    @include mixins.flex-row(center, flex-end);

    gap: 1.5rem;
    width: 100%;

    &-settings {
      margin-right: auto;
    }
  }
}
