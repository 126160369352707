@use '../../../styles/mixins' as mixins;

@include mixins.animation(typing) {
  25% {
    content: '.';
  }
  50% {
    content: '..';
  }
  75% {
    content: '...';
  }
}

.chat-history {
  @include mixins.flex-column(center, initial);

  width: calc(100% - 23.25rem);

  &__no-content {
    @include mixins.flex-column();

    width: 100%;
    height: 100%;
    gap: 1rem;

    &-label {
      color: #1e1e1e;
      font-weight: normal;
    }
  }

  &__no-access {
    @include mixins.flex-column();

    background-color: #f3f3f6;
    width: calc(100% - 4rem);
    padding: 2rem;
    gap: 1rem;
    margin: 4.5rem 4.5rem 0;

    &-name {
      @include mixins.font-style(1.25rem, #43445b, 600);

      word-break: break-word;
    }

    &-purpose {
      @include mixins.font-style(1rem, #787878);

      word-break: break-word;
    }
  }

  &__header {
    @include mixins.flex-row(center, flex-start);

    width: calc(100% - 2rem);
    padding: 1rem;
    height: 2.5rem;
    border-bottom: 0.0625rem solid #ebebeb;
    gap: 1rem;

    &-user {
      @include mixins.flex-row(center, flex-start);
      @include mixins.ellipsis();

      gap: 1rem;
      cursor: default;

      &--clickable {
        cursor: pointer;
      }

      &-name {
        @include mixins.ellipsis();
        @include mixins.font-style(1rem, #1e1e1e, 600);
      }
    }

    &-info {
      margin-left: auto;
    }
  }

  &__body {
    @include mixins.flex-column();

    width: 100%;
    height: calc(100vh - 11rem);

    &-loader {
      @include mixins.flex-column(initial, flex-start);

      gap: 1rem;
      padding: 1rem;
      width: calc(100% - 2rem);
    }

    &-typing-users {
      @include mixins.font-style(0.875rem, #1e1e1e, 600);
      @include mixins.ellipsis();

      padding: 0.25rem;

      &:after {
        @include mixins.animation-use(typing, 1000ms);

        position: absolute;
        content: '';
      }
    }

    &-messages {
      @include mixins.custom-scrollbar();

      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 100%;

      &-scroll {
        display: flex;
        flex-direction: column-reverse;
        gap: 0.5rem;
        padding: 1rem 1rem 0.5rem 1rem;
      }

      &-author {
        @include mixins.flex-row(center, flex-start);

        gap: 0.5rem;
        margin-top: 1rem;
        max-width: calc(100% - 3rem);

        &-name {
          @include mixins.font-style(0.875rem, #1e1e1e, 600);

          white-space: pre-wrap;
          word-break: break-word;
        }
      }

      &-item {
        @include mixins.flex-row(center, flex-start);

        position: relative;
        gap: 0.5rem;
        margin-left: auto;
        max-width: calc(100% - 6rem);
        margin-bottom: 0.5rem;

        &--has-reaction {
          margin-bottom: 1.25rem;
        }

        &-wrapper {
          @include mixins.flex-column(initial);

          width: 100%;
          gap: 0.75rem;
        }

        &-date {
          @include mixins.font-style(1rem, #1e1e1e, 600);

          text-align: center;
        }

        &-server-message {
          @include mixins.font-style(1rem, #787878);

          text-align: center;
          word-break: break-word;
        }

        &:after {
          content: '';
          position: absolute;
          top: calc(100% - 0.5rem);
          right: 0;
          width: 0;
          height: 0;
          border: 0.5rem solid black;
          border-color: transparent #f37354 transparent transparent;
        }

        &--deleted::after {
          border-color: transparent #c9c9c9 transparent transparent;
        }

        &-content {
          @include mixins.font-style(1rem, #fff);

          min-width: 4.5rem;
          line-height: 1.5rem;
          padding: 0.5rem 0.5rem 0.75rem 0.5rem;
          background-color: #f37354;
          word-break: break-word;
          white-space: pre-wrap;
          border-radius: 0.5rem;
        }

        &-time {
          @include mixins.font-style(0.7rem, #1e1e1e);

          position: absolute;
          right: 0.5rem;
          bottom: 0;
        }

        &-reaction {
          position: absolute;
          right: 0.5rem;
          bottom: -2rem;
        }

        &-icon {
          opacity: 0;
        }

        &:hover .chat-history__body-messages-item-icon {
          opacity: 1;
        }

        &--other {
          margin-right: auto;
          margin-left: unset;
          flex-direction: row-reverse;

          &:after {
            left: 0;
            border-color: transparent transparent transparent #fcf1ee;
          }

          &.chat-history__body-messages-item--deleted:after {
            border-color: transparent transparent transparent #c9c9c9;
          }

          & .chat-history__body-messages-item-content {
            color: #1e1e1e;
            background-color: #fcf1ee;
          }

          & .chat-history__body-messages-item-time {
            left: 0.5rem;
          }

          & .chat-history__body-messages-item-reaction {
            left: 0.5rem;
          }
        }

        &--deleted .chat-history__body-messages-item-content {
          background-color: #c9c9c9;
        }

        &-emoji-picker {
          @include mixins.position-centered();

          margin-top: -2rem;
        }
      }

      &-loader {
        @include mixins.flex-column(initial);

        width: calc(100% - 2rem);
        height: 100%;
        padding: 0 1rem;
        gap: 1rem;
      }
    }

    &-file {
      display: none;
    }

    &-input {
      @include mixins.custom-scrollbar();
      @include mixins.font-style(0.875rem, #1e1e1e);

      border-width: 0.0625rem 0 0 0;
      border-color: #ebebeb;
      padding: 1rem 2.5rem 0 1rem;
      max-height: 10rem;
      overflow-y: auto;
      border-radius: 0;

      &-wrapper {
        position: relative;
        width: 100%;

        &-scroll-down {
          @include mixins.position-centered();

          top: -2.75rem;
          border: 0.0625rem solid #f0f0f0;
          background-color: #fff;
          box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          padding: 0.5rem;
          z-index: 1;
        }
      }

      &-icons {
        @include mixins.flex-row(center, flex-start);

        width: calc(100% - 2rem);
        padding: 0 1rem;
        gap: 0.75rem;

        &-send,
        &-loader {
          margin-left: auto;
        }
      }
    }
  }

  &__emoji-picker {
    position: absolute;
    bottom: 2.5rem;
    right: 1rem;
    z-index: 999;
  }
}
